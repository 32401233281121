import React from "react"
import * as styles from "./breadcrum.module.scss"
import { Link } from "@reach/router"
import { useLocalPath } from "../hooks/useLocalPath"

type Props = {
    data: [],
    title: String
}
const Breadcrum = ({ data, title }: Props) => {
  
  function toSentenceCase(str) {
    if (!str || str.length === 0) {
      return '';
    }
  
    const firstChar = str.charAt(0).toUpperCase();
    const restOfStr = str.slice(1).toLowerCase();
  
    return firstChar + restOfStr;
  }

  return (
    <div className={`container ${styles.strip}`}>
      <div className={` container-lg ${styles.wrapper}`}>
        {data.map((item: {slug: String, title: String}, id: number) => {
          return (
            <span className={styles.breadcrumLink} key={id}>
              <Link to={useLocalPath(item.slug) }>{toSentenceCase(item.title)}</Link>
              <span> / </span>
            </span>
          )
        })}
        <span className={styles.breadcrumTitle}> {toSentenceCase(title.split(' / ').join(' ').toLowerCase()) }</span>
      </div>
    </div>
  )
}

export default Breadcrum
