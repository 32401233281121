// extracted by mini-css-extract-plugin
export var alternateGrid = "alternateSection-module--alternate-grid--a3058";
export var alternateSectionSubTitle = "alternateSection-module--alternate-section-sub-title--28d55";
export var arrow = "alternateSection-module--arrow--22b04";
export var arrowImg = "alternateSection-module--arrow-img--ba337";
export var blogContent = "alternateSection-module--blog-content--01548";
export var blogContentImage = "alternateSection-module--blog-content-image--f1f46";
export var blogContentWrapper = "alternateSection-module--blog-content-wrapper--e0aae";
export var button = "alternateSection-module--button--33da3";
export var cafeFormIntro = "alternateSection-module--cafe-form-intro--5f26e";
export var cafe_title = "alternateSection-module--cafe_title--76249";
export var content = "alternateSection-module--content--44e12";
export var contentImage = "alternateSection-module--content-image--57657";
export var contentWrapper = "alternateSection-module--content-wrapper--3cf25";
export var cta = "alternateSection-module--cta--adcb9";
export var highlight = "alternateSection-module--highlight--33baf";
export var img = "alternateSection-module--img--8506c";
export var left = "alternateSection-module--left--15ebb";
export var readmore = "alternateSection-module--readmore--6f4f8";
export var redButton = "alternateSection-module--red-button--3d530";
export var redTransparentButton = "alternateSection-module--red-transparent-button--70a51";
export var right = "alternateSection-module--right--08187";
export var subTitle = "alternateSection-module--sub-title--dd68e";
export var title = "alternateSection-module--title--caf1a";
export var titleWrapper = "alternateSection-module--title-wrapper--97f88";
export var transparentButton = "alternateSection-module--transparent-button--3dc54";
export var wrapper = "alternateSection-module--wrapper--f215f";