// extracted by mini-css-extract-plugin
export var breadcrumLink = "breadcrum-module--breadcrum-link--6c8e6";
export var breadcrumTitle = "breadcrum-module--breadcrum-title--dd3f0";
export var button = "breadcrum-module--button--8e7ce";
export var cafeFormIntro = "breadcrum-module--cafe-form-intro--2eb2f";
export var highlight = "breadcrum-module--highlight--64e72";
export var redButton = "breadcrum-module--red-button--0b17a";
export var redTransparentButton = "breadcrum-module--red-transparent-button--123fc";
export var strip = "breadcrum-module--strip--a0844";
export var transparentButton = "breadcrum-module--transparent-button--fa075";
export var wrapper = "breadcrum-module--wrapper--26e34";