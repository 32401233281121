import React from 'react'
import {graphql} from 'gatsby'
import HeroComponent from '../components/HeroComponent/HeroComponent'
import SectionBlogText from '../components/SectionBlogText/SectionBlogText'
import ComponentContent from '../components/ComponentContent/ComponentContent'
import SectionGrid from '../components/SectionGrid/SectionGrid';
import Seo from '../components/seo'
import { useUrl } from '../fragments/hooks/useUrl'
import AlternateSection from '../components/AlternateSection/AlternateSection'
import Breadcrum from '../components/Breadcrum/Breadcrum'

const ProductBlog = ({
  pageContext,
    data: {
        contentfulPageProductBlog:{
        slug,
        seoTitle,
        seoDescription,
        socialMedia,
        sections,
        breadcrum,
        thumbnailTitle
      },
      allContentfulPageProductBlog:{
        nodes
      }
    }
  }) => {
    const lang = useUrl()
    if(!sections){
        return <div></div>
      }
      console.log(slug);
      
  return (
    <section id = 'product-blog'>
      <Seo
        slug={slug}
        title={seoTitle}
        description={seoDescription && seoDescription.seoDescription}
        node_locale={pageContext.node_locale}
        lang={lang}
        hrefLangs={nodes}
        />
        {breadcrum && <Breadcrum data ={breadcrum} title = {thumbnailTitle}/>}
        {
        sections.map((section, index) =>{
          switch(section.__typename){
            case "ContentfulComponentHero":
              return <HeroComponent data = {section} key = {index}/>
            case "ContentfulComponentContent":
                return <SectionBlogText data = {section} key = {index} socialMedia = {socialMedia}/>
            case "ContentfulComponentGridSection":
                return <SectionGrid data = {section} key = {index} />
            case "ContentfulComponentAlternateSection":
            return <AlternateSection data={section} key={index} alternateBlog = 'true'/>
          }
        })
      }
   
      {slug == 'tea-latte-mix' && <div data-crl8-container-id="product"></div>}
    </section>
  )
}

export const ProductBlogQuery = graphql`
    query ProductBlogQuery( $id: String!, $contentful_id: String! ){
        contentfulPageProductBlog(id: {eq: $id}){
            slug
            seoTitle
            seoDescription {
                seoDescription
            }
            breadcrum {
              title
              slug
            }
            thumbnailTitle
            socialMedia
            sections {
                __typename
                ...componentHero
                ...componentContent
                ...contentfulComponentGridSection
                ...alternateSection
            }
        }       
        allContentfulPageProductBlog(
            filter: {
                contentful_id: { eq: $contentful_id },
                id: { ne: $id },
                slug: { ne: null }
            }
        ) {
            nodes {
                slug
                node_locale
            }
        }  
    }
  `
export default ProductBlog