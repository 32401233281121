// extracted by mini-css-extract-plugin
export var banner = "heroComponent-module--banner--a81a2";
export var bannerWrap = "heroComponent-module--banner-wrap--3e625";
export var btn = "heroComponent-module--btn--d80ca";
export var button = "heroComponent-module--button--0a678";
export var cafeFormIntro = "heroComponent-module--cafe-form-intro--be666";
export var highlight = "heroComponent-module--highlight--8a84b";
export var mobileBanner = "heroComponent-module--mobile-banner--b6ce0";
export var redButton = "heroComponent-module--red-button--3ae66";
export var redTransparentButton = "heroComponent-module--red-transparent-button--13e28";
export var spaceBannerWrap = "heroComponent-module--space-banner-wrap--2e4d7";
export var srOnly = "heroComponent-module--sr-only--d9b6e";
export var subText = "heroComponent-module--sub-text--60c46";
export var subTitle = "heroComponent-module--sub-title--e689f";
export var text = "heroComponent-module--text--4a000";
export var title = "heroComponent-module--title--c7937";
export var transparentButton = "heroComponent-module--transparent-button--de439";