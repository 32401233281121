import React from 'react'
import Title from '../Shared/Title/Title'
import { RichText } from '../Shared/RichText/RichText'
import * as styles from './sectionBlogText.module.scss'
import SocialMedia from '../SocialMediaIcons/SocialMedia'
const SectionBlogText = ({ data, socialMedia }) => {
    const {
        title,
        titleBackgroundColor,
        titleColor,
        headingLevel,
        subTitle,
        titleAlignment,
        leftVine,
        rightVine,
        content,
        backgroundColor,
        backgroundImage,
        cta,
        sectionWidth,
        contentColor,
        contentAlignment,
        additionalContent } = data

    const alignmentTitle = (alignment) => {
        switch (alignment) {
            case 'left':
                return styles.left
            case 'center':
                return styles.center
            default:
                return styles.left
        }
    }
    return (
        <div className='container container-lg'>
            <div className={styles.wrapper}>
                <div  style={{ color: titleColor ? titleColor : '', background: titleBackgroundColor ? titleBackgroundColor : "" }} className = { ` ${styles.title} ${alignmentTitle(titleAlignment)}`} ><Title title={title} headingLevel={headingLevel} /></div>
                {subTitle && <div  style={{ color: titleColor ? titleColor : '', background: titleBackgroundColor ? titleBackgroundColor : "" }} className = {styles.subtitle}><Title title={subTitle} headingLevel='h3' /></div>}
                {content &&
                    <div style={{ color: contentColor ? contentColor : '' }} className = {styles.desc} >
                        <RichText content={content} />
                    </div>
                }
                {socialMedia ? <SocialMedia marginSpace/> : '' }
            </div>
        </div>
    )
}

export default SectionBlogText