import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import * as styles from './heroComponent.module.scss'
import { Link } from 'gatsby'
import { useLocalPath } from '../hooks/useLocalPath'
const HeroComponent = ({data}) => {
  const{heroImage, heroImageMobile, ctaButton, title, subTitle} = data

  return (
    <div className='container'>
      <div className={title ? styles.spaceBannerWrap : styles.bannerWrap}>
        <div className={styles.title}>
          {title ? <h1>
            {title.split('/').map((text:any, id:number) =>{
              return <><span className={styles.text}>{text}</span> <br /></>
            })}
          </h1> : <h1 className={styles.srOnly}> Hero Image Title </h1>}
        </div>
        <div className={styles.subTitle}>
          {subTitle && <h2>
            {subTitle.split('/').map((text:any, id:number) =>{
              return <><span className={styles.subText}>{text}</span> <br /></>
            })}
          </h2>}
        </div>
        <div className={styles.mobileBanner}>
          <GatsbyImage
              image={heroImageMobile?.gatsbyImageData}
              alt={`${heroImageMobile?.title} image`}
              loading="eager"
              objectFit='cover'
          />
        </div>
        <div className={styles.banner}>
          <GatsbyImage
              image={heroImage?.gatsbyImageData}
              alt={`${heroImage?.title} image`}
              loading="eager"
              objectFit='cover'
          />
        </div>
        {ctaButton && <div className={styles.btn}>
          <Link to = {ctaButton.externalLink ? ctaButton.externalLink :useLocalPath(ctaButton.slug)} target = {ctaButton.externalLink ? '_blank' : '_self'}><button className='red-button'>{ctaButton.title}</button></Link>
        </div>}
      </div>
    </div>
  )
}

export default HeroComponent