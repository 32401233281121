import React, {useState} from "react"
import Title from "../Shared/Title/Title"
import * as styles from "./alternateSection.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import { RichText } from "../Shared/RichText/RichText"
import { useLocalPath } from "../hooks/useLocalPath"
import {Link} from 'gatsby'
const AlternateSection = ({ data,alternateBlog }) => {
  
  const {
    alignment,
    arrow,
    content,
    additionalContent,
    backgroundColor,
    image,
    cta,
    title,
    titleBreak,
    firstItalicContent,
    titleFontSize,
    titleBackgroundColor,
    titleColor,
    subTitle,
    contentBackgroundColor,
    contentColor,
    subTitleHeadingLevel,
  } = data

  const alignmentText = (alignment) => {
    switch (alignment) {
      case 'Left':
        return styles.left
      case 'Right':
        return styles.right
      default:
        return styles.left
    }
  }
  const [readmore, setReadmore] = useState(false)
  
  return (
    <div className="container" style={{ background: backgroundColor ? backgroundColor : "" }}>
      <div className={`container-lg ${styles.wrapper} `}>
        {title && 
          <div className={styles.titleWrapper}>
            <div style={{ background: titleBackgroundColor ? titleBackgroundColor : "", color : titleColor ? titleColor : "" }} className = {titleFontSize ? styles.cafe_title : styles.title}> <Title title = {title} firstItalicContent={firstItalicContent} titleBreak={titleBreak}/> </div>
          </div>}
          {arrow && <div className={styles.arrow}>
              <GatsbyImage 
                image={arrow.gatsbyImageData}
                alt={`${arrow.title} image`}
                className = {styles.arrowImg}
              />
            </div>}
        <div className={`${styles.alternateGrid} ${alignmentText(alignment)}`} style={{ background: contentBackgroundColor ? contentBackgroundColor : "" }}>
          {image && (
            <div className={alternateBlog ? styles.blogContentImage : styles.contentImage}>
              <GatsbyImage 
                image={image.gatsbyImageData} 
                alt={`${image.title} image`} 
                className = {styles.img} />
            </div>
          )}
          <div className={alternateBlog ? styles.blogContentWrapper : styles.contentWrapper} >
            <div className={alternateBlog ? styles.blogContent : styles.content}>
              {subTitle && <div style={{ color: titleColor }} className={styles.subTitle}><Title headingLevel = {subTitleHeadingLevel} title={subTitle} /></div>}
                <div style={{color: contentColor}}>
                  <RichText content={content} />
                </div>
                {additionalContent && <div style={{color: contentColor}}>
                  {readmore && <RichText content={additionalContent} />}
                  <p className = {styles.readmore} onClick={() => setReadmore(!readmore)}>{readmore ? 'Read Less' : 'Read More'}</p>
                  
                </div>}
              <div>
                {cta && <Link to = {useLocalPath(cta.slug)}><button className={`red-button ${styles.cta}`}>{cta.title}</button> </Link>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlternateSection
