// extracted by mini-css-extract-plugin
export var button = "sectionBlogText-module--button--e8035";
export var cafeFormIntro = "sectionBlogText-module--cafe-form-intro--f5db9";
export var center = "sectionBlogText-module--center--f7921";
export var desc = "sectionBlogText-module--desc--ae3a6";
export var highlight = "sectionBlogText-module--highlight--2b959";
export var left = "sectionBlogText-module--left--b67c0";
export var redButton = "sectionBlogText-module--red-button--6adf3";
export var redTransparentButton = "sectionBlogText-module--red-transparent-button--58db5";
export var subtitle = "sectionBlogText-module--subtitle--ce5a3";
export var title = "sectionBlogText-module--title--39ded";
export var transparentButton = "sectionBlogText-module--transparent-button--7deed";
export var wrapper = "sectionBlogText-module--wrapper--47356";